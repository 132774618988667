import React from "react";
import Checkout from '../../components/checkout';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#f7b74f',
      main: '#F5A623',
      dark: '#ab7418',
      contrastText: '#fff',
    },
  }
});

export default function CheckoutPage() {
  return (
    <MuiThemeProvider theme={theme}>
      <Checkout/>
    </MuiThemeProvider>
  );
}
