import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import CheckIcon from '@material-ui/icons/Check';
import bugs from "../../assets/images/bugs.jpeg";
import wheels from "../../assets/images/wheels.jpeg";
import vacuum from "../../assets/images/inside-wash.jpg";
import wax from "../../assets/images/detail.jpg";
import ceramic from "../../assets/images/ceramic.jpg";
import clay from "../../assets/images/clay.jpg";
import windows from "../../assets/images/interior-windows.jpg";
import wipedown from "../../assets/images/interior-wipe.jpg";
import wash from "../../assets/images/wash.jpg";
import Store from '../../services/store.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

let setPicked = false;

const Selectable = (props) => {
  const { upsell, selected } = props;
  const title = names[upsell];
  const desc = descs[upsell];
  const price = prices[upsell]; //Make it pull from FB
  const anchor = anchors[upsell];

  const toggleSelected = (upsell) => {
    if(upsell === 'wash') return;
    let newSelected;
    if( selected.includes(upsell) ) newSelected = selected.filter(x => x !== upsell);
    else newSelected = [...selected, upsell];

    const update = { target:{
        id: 'items',
        value: newSelected.map(createItem)
      }
    };

    Store.update('order', update);
    if(setPicked) setPicked(newSelected);
  };

  const classes = useStyles();

  return (
    <ButtonBase className={classes.root} onClick={() => toggleSelected(upsell)}>
      <Paper className={classes.paper} style={{padding:0}}>
        <Grid container direction='row' spacing={0}>
          <Grid item style={{position: 'relative'}}>
              <img className={classes.img} src={images[upsell]} />
              { selected.includes(upsell) && (
                <>
                  <div
                    style={{width: '100%', height: '100%', opacity: 0.5, position: 'absolute', left: 0, top: 0, backgroundColor: '#F5A623'}}
                  />
                  <CheckIcon
                    style={{width: '100%', height: '100%', color: '#FFFFFF', position: 'absolute', left: 0, top: 0, marginBottom: 2}}
                  />
                </>
              ) }
          </Grid>
          <Grid item xs={12} sm container direction="column">
            <Grid item xs>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="h6" style={{textAlign: 'left', marginTop: 5, marginLeft: 10}}>
                  {title}
                </Typography>
                <Typography variant="subtitle2" style={{textAlign: 'right', display:'flex', alignItems: 'center', marginTop: 5, marginRight: 10}}>
                  <Typography variant="caption" style={{textDecorationLine: 'line-through', marginRight: 4}}>
                    ${anchor}
                  </Typography>
                  {upsell === 'wash' ? 'Free' : `$${price}`}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item alignItems="left">
            <Typography variant="body2" gutterBottom style={{textAlign: 'left', marginTop: 2, marginLeft: 10}}>
              {desc}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </ButtonBase>
  );
}

export default function ServiceSelector() {
  const products = ['wash', 'bugs', 'windows', 'wipedown', 'vacuum', 'wheels', 'wax', 'ceramic', 'clay'];  //Pull from FB and sync with app
  const [selected, setSelected] = React.useState(!!Store.order.items ? Store.order.items.map(x => x.product) : ['wash']);
  setPicked = setSelected;
  const [loading, setLoading] = React.useState(true);

  // React.useEffect(() => {
  //   async function getProducts(){
  //     try{
  //       const servicePrices = await firebase.getPrices();
  //       setProducts(servicePrices);
  //     }
  //     catch(e){
  //       console.log(e);
  //     }
  //     setLoading(false);
  //   };
  //   getProducts();
  // }, []);


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        What services would you like?
      </Typography>
      <Grid container spacing={3} direction='column' alignItems='center'>
        {
          products.map(upsell =>
            <Grid key={upsell} item xs={12} sm={6} alignItems='center'>
              <Selectable key={upsell} upsell={upsell} selected={selected} />
            </Grid>
          )
        }
      </Grid>
    </React.Fragment>
  );
}


const anchors = {
  bugs: 19.99,
  wheels: 19.99,
  vacuum: 24.99,
  wax: 19.99,
  ceramic: 59.99,
  clay: 59.99,
  windows: 9.99,
  wipedown: 19.99,
  wash: 19.99,
};


const names = {
  bugs: "Bug Removal",
  wheels: "Wheel Wash",
  vacuum: "Vacuum",
  wax: "Express Wax",
  ceramic: "Ceramic Coating Protection",
  clay: "Clay Paint Decontamination",
  windows: "Inside Window Wash",
  wipedown: "Inside Wipedown + UV Protection",
  wash: "Exterior Hand-wash"
};

const descs = {
  bugs: "Remove all love bugs with our enhanced bug remover.",
  wheels: "A full tire and wheel hand wash with no acid.",
  vacuum: "A full interior vacuum to pick up any crumbs and dirt.",
  wax: "A professional grade express wax to protect your car's paint.",
  ceramic: 'Protect your paint from the road with a full body ceramic shield.',
  clay: 'Give your car\'s paint the spa treatment with a full body clay bar scrub.',
  windows: 'Keep the your windows interiors safe and streak free.',
  wipedown: 'Clean and protect your vehicles interior from dust and harmful UV rays',
  wash: 'A full exterior hand-wash with a blow dry to make your car shine like new.',
};

const prices = {
  bugs: 9.99,
  wheels: 11.99,
  vacuum: 9.99,
  wax: 9.99,
  ceramic: 49.99,
  clay: 49.99,
  windows: 5.99,
  wipedown: 13.99,
  wash: 0,
};

const images = {
  bugs,
  wheels,
  vacuum,
  wax,
  ceramic,
  clay,
  windows,
  wipedown,
  wash,
};

const createItem = (upsell) => ({
    id: names[upsell],
    product: upsell,
    price: prices[upsell],
    quantity: 1,
    discount: 0,
});

Store.update( 'order',  { target: { id: 'items', value:[createItem('wash')] }} );
