import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Checkout from '../../components/checkout';
import { Redirect } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from "react-helmet";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {isAndroid, isIOS, isMobile} from 'react-device-detect';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#f7b74f',
      main: '#F5A623',
      dark: '#ab7418',
      contrastText: '#fff',
    },
  }
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://washr.app/">
        Washr, Inc
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(4, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const redirect = () => {
  if(isIOS) window.location = 'https://apps.apple.com/us/app/fillr/id1438441935';
  if(isAndroid) window.location = 'https://play.google.com/store/apps/details?id=com.omni.vida';
  ReactPixel.trackCustom('Download', {});
}

export default function DownloadPage() {
  const classes = useStyles();
  redirect();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Washr
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Download
          </Typography>
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Thank you fo dowloading!
            </Typography>
            <Typography variant="subtitle1">
              You are now being redirected to the download page for your phone brand.  If your browser does not redirect automatically, please use the button below.
            </Typography>
            <Button
              variant="contained"
              color="orange"
              onClick={redirect}
              className={classes.button}
            >
              Download Washr
            </Button>
          </React.Fragment>
        </Paper>
        <Copyright />
        <Helmet>
          <script>
            {`
              gtag('event', 'conversion', {'send_to': 'AW-697189278/IIoSCM-00cIBEJ6HucwC'});
            `}
          </script>
        </Helmet>
      </main>
    </MuiThemeProvider>
  );
}
