import './App.css';
import React from 'react';
import { Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import history from './services/history';
import Routes from './routes';

function App() {
  return (
    <Router history={history}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Routes />
      </MuiPickersUtilsProvider>
    </Router>
  );
}

export default App;
