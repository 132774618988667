import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AppointmentForm from './AppointmentForm';
import ServiceSelector from './ServiceSelector';
import PaymentForm from './PaymentForm';
import Review from './Review';
import Store from '../../services/store.js';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helmet } from "react-helmet";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
const stripeKey = process.env.NODE_ENV === 'development' ? 'pk_test_aZzL2AXYaZ3bOP2RpYtVO2wB' :'pk_live_rKNXMfQJXJmY9KCAb9a3TmYU';
const stripePromise = loadStripe(stripeKey);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://washr.app/">
        Washr, Inc
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(4, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const steps = ['Service details', 'Appointment details', 'Payment details', 'Review your order'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <ServiceSelector />;
    case 1:
      return <AppointmentForm />;
    case 2:
      return <Elements stripe={stripePromise}> <PaymentForm /> </Elements>;
    case 3:
      return <Review />;
    // default:
    //   throw new Error('Unknown step');
  }
}

export default function Checkout() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [disabled, setDisabled] = React.useState(false);
  Store.setDisabled = setDisabled;
  const [loading, setLoading] = React.useState(false);
  const toggleLoading = (override = loading) => setLoading(!override);
  Store.toggleLoading = toggleLoading;
  const [error, setError] = React.useState(false);
  const openError = () => setError(true);
  Store.openError = openError;
  const [message, setMessage] = React.useState('');
  Store.setMessage = setMessage;
  Store.loadPromoCodes();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setError(false);
  };

  const handleNext = async () => {
    if(activeStep === 2 && !Store.payment.card){
      Store.toggleLoading();
      await Store.getPaymentMethod();
      await Store.creatingUser;
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
    setActiveStep(activeStep + 1);
    setDisabled( !Store.pageReady(activeStep + 1) );
  };

  const handleBack = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setActiveStep(activeStep - 1);
    setDisabled( !Store.pageReady(activeStep - 1) );
  };

  const placeOrder = async () => {
    toggleLoading();
    const success = await Store.checkout();
    if(success) handleNext();
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Washr
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
          <Stepper alternativeLabel activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Thank you for your order! We have texted you an order confirmation.  You can also download the app to track,
                  update, or cancel your order by using the button below.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href={'/download'}
                  className={classes.button}
                >
                  Download Washr
                </Button>
                <Helmet>
                  <script>
                    {`
                      gtag('event', 'conversion', {'send_to': 'AW-697189278/IIoSCM-00cIBEJ6HucwC'});
                    `}
                  </script>
                </Helmet>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    disabled={disabled}
                    variant="contained"
                    color="primary"
                    onClick={activeStep === steps.length - 1 ? placeOrder: handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
        <Backdrop className={classes.backdrop} open={loading} onClick={toggleLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          onClose={handleClose}
          message={message}
        />
      </main>
    </React.Fragment>
  );
}
