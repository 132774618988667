import '../App.css';
import { Switch, Route } from 'react-router-dom';

import Checkout from '../pages/Checkout';
import Download from '../pages/Download';

function Routes() {
  return (
    <Switch>
      <Route path="/" component={Checkout} />
      <Route path="/download" component={Download} />
      {/* redirect user to page below if route does not exist */}
      <Route component={Checkout} />
    </Switch>
  );
}

export default Routes;
