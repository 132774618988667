import app from 'firebase/app';
require("firebase/functions");
require("firebase/firestore");
const { GeoFirestore } = require('geofirestore');

const config = process.env.NODE_ENV === 'development' ?
{
  apiKey: "AIzaSyDQp1e7JVyeRzictl_Yda40WD14CeqILYk",
  authDomain: "omni-test-f5842.firebaseapp.com",
  databaseURL: "https://omni-test-f5842.firebaseio.com",
  projectId: "omni-test-f5842",
  storageBucket: "omni-test-f5842.appspot.com",
  messagingSenderId: "727004193576",
  appId: "1:727004193576:web:0dcd186adcab3d1844a61d",
  measurementId: "G-M1XFPB6WSG"
}
: {
  apiKey: "AIzaSyCbN57ey07wtiRq2QJmkZrWELKTBlYzn20",
  authDomain: "omni-prod-4ca33.firebaseapp.com",
  databaseURL: "https://omni-prod-4ca33.firebaseio.com",
  projectId: "omni-prod-4ca33",
  storageBucket: "omni-prod-4ca33.appspot.com",
  messagingSenderId: "733535118903",
  appId: "1:733535118903:web:a162a012d19e6ca789c1b2",
  measurementId: "G-YJ4Z7BQM7C"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.geo = new GeoFirestore(app.firestore());
    Object.assign(this, app);
  }

  getPrices() {
    return 10;
  }

}

const firebase = new Firebase();
export default firebase;
