import Store from "./store.js";
var base64 = require('base-64');

const identify = (input = {}) => {
  const { user } = Store;
  let data = {
    "token" : "KGYyRm",
    "properties": {
      ...input,
      "$email": user.email,
      "$id": user.id,
      "$first_name": user.firstName,
      "$last_name": user.lastName,
      "$phone_number": user.phoneNumber,
    }
  };
  sendRequest("identify", data);
};

const track = (event, userData = {}, eventData = null) => {
  const { user } = Store;
  userData = { ...userData, "$id": user.id };

  let data = {
    event,
    "token" : "KGYyRm",
    "customer_properties": userData,
    "properties": eventData,
  };

  sendRequest("track", data);
};


const sendRequest = (type, data) => {
  data = JSON.stringify(data);
  let encoded = base64.encode(data);
  const url = `https://a.klaviyo.com/api/${type}?data=${encoded}`;
  if(process.env.NODE_ENV === 'development') return console.log(data, url);
  return fetch(url).catch((e) =>  console.log(e));
};

export default {
  identify,
  track,
};
