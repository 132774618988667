import Firebase from './firebase.js';
import RequestManger from './requestManager.js';
import klaviyo from './klaviyo.js';
import Geocode from 'react-geocode';
import ReactPixel from 'react-facebook-pixel';
import MomentUtils from '@date-io/moment';
const moment = new MomentUtils();

class Store{
  constructor(){
    let startDay = moment.date().add(1, 'days');
    if(startDay.day() === 3 || startDay.day() === 7) startDay.add(1, 'days');
    this.order={status: 'active', orderDate: new Date(), selectedDate: startDay, start:moment.date().hour(8).minute(0), end:moment.date().hour(11).minute(0)};
    this.subscription = {id: 'unlimited-annual', name: 'Annual', price: 39, days: 7};
    this.user = {};
    this.payment = {};
    this.car = {};
    Geocode.setApiKey("AIzaSyCbN57ey07wtiRq2QJmkZrWELKTBlYzn20");
  }

  getPaymentMethod = () => null;

  update = (object, e) => this[object][e.target.id] = e.target.value;

  userIsValid = () => Object.values(this.user).length >= 4;
  carIsValid = () => Object.values(this.car).length === 4;
  orderIsValid = () => !!this.order.shipping && !!this.order.start && !!this.order.end;// && !!this.order.items;

  paymentIsValid = () => Object.values(this.payment).length === 3;

  pageReady = (step) => {
    switch (step) {
      case 0:
        return true;
      case 1:
        return this.userIsValid() && this.carIsValid() && this.orderIsValid();
      case 2:
        return !!this.paymentComplete;
      case 3:
        return true;
      // default:
      //   throw new Error('Unknown step');
    }
  };

  loadPromoCodes = async () => {
    const docSnap = await Firebase.firestore().collection('promos').get();
    this.promos = {};
    if(docSnap.empty) return Promise.resolve(false);
    for (var i = 0; i < docSnap.docs.length; i++) {
      const doc = docSnap.docs[i];
      this.promos[doc.id] = {...doc.data()};
    }
    return Promise.resolve(this.promos);
  }

  applyPromo = () => {
    const code = this.subscription.promo.toLowerCase();
    const activeCodes = new RegExp( Object.keys(this.promos).join("|"), "i" );
    const success = activeCodes.test(code);
    if(success){
      const promo = this.promos[code];
      let message;
      if(promo.type === 'credit'){
        message = `Code applied!  Enjoy your credit $${promo.price}!`;
        this.order.items.push({ id: 'Promo credits', product: 'credit', price: promo.price, quantity: 1, discount: 0 });
      }
      else if(promo.type === 'subscription'){
        message = `Code applied!  Enjoy your ${promo.trial} day free trial!`;
        this.subscription.days = promo.trial;
        this.setTrial(promo.trial);
      }
      this.setMessage(message);
      this.openError(true);
      Firebase.firestore().doc(`promos/${code}`).update({activations: Firebase.firestore.FieldValue.increment(1)});
    }

    //   if(this.setSubPrice) this.setSubPrice(this.promos[code].price);
    //   Firebase.firestore().doc(`promos/${code}`).update({activations: Firebase.firestore.FieldValue.increment(1)});
    //   return {success, promotion: {...this.promos[code]}};
    // }
    // else return {success};
  };

  createUser = async () => {
    try{
      let { user } = this;
      this.creatingUser = true;

      //Create user if needed.  Else load user data.
      let params = {
        function: 'createUser',
        variables: {...user, phoneNumber: "+1"+user.phoneNumber.replace(/[^0-9]/g, '') },
      };
      let response = await RequestManger.post(params);


      if(!response.success) {
        //Input error posibility.  Check and return
        if(response.code.includes('invalid')){
          this.creatingUser = false;
          return Promise.reject('User information is incorrect');
        }

        //Not an input error.  The customer already exists.  Just check for it in the database.
        const searchTerm = response.message.includes('email') ? 'email' : 'phoneNumber';
        const redundantUsers = await Firebase.firestore().collection('people').where(searchTerm, '==', user[searchTerm]).get();
        user = redundantUsers.docs[0].data();
        this.user.id = redundantUsers.docs[0].id;
      }
      else{
        //Was first time user and account was created successfully.
        this.user.id = response.uid;
        //Trigger in CRM for drips
        const signUpDate = moment.date().format('YYYY-MM-DD HH:MM:SS');
        klaviyo.identify({signUpDate, onBoarding: true});
        klaviyo.track('start onboard');
      }

      return Promise.resolve(true);
    }
    catch(e){
      //do things.
      this.setMessage(e.message || 'An uknown error occurred.  Please try again');
      this.openError(true);
      this.toggleLoading(true);
      return Promise.resolve(false);

    }
  };

  checkout = async () => {
    try{

      let { user, order, subscription, payment, car } = this;
      let response, params;


      // //Create payment method
      // const {error, paymentMethod} = await this.getToken();
      // if (error) console.log(error.message); //NEED ERROR HANDLING HERE

      //Create customer
      const token = this.payment.id;
      // console.log(token);
      if(!user.stripeID) {
        params = {
          function: 'createCustomer',
          contentType: 'application/x-www-form-urlencoded',
          variables: {
            payment_method: token,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            userID: user.id,
          },
        };
        response = await RequestManger.post(params);
        if(response.message) Promise.reject(response.message)
        user.stripeID = response.token;
      }


      //Subscribe customer if needed
      if(!user.subscribed) {
        params = {
          function: 'subscibeCustomer',
          contentType: 'application/x-www-form-urlencoded',
          variables: {
            customer: user.stripeID,
            plans: [subscription.id],
            days: subscription.days,
          },
        };

        response = await RequestManger.post(params);
        if(response.message) Promise.reject(response.message);
        const subscriptionDate = new Date();
        this.user.subscribed = true;
        Firebase.firestore().doc(`people/${this.user.id}`).update({subscriptionDate, subscribed: true});
        ReactPixel.track('Subscribe', {
          content_name: subscription.id,
          currency: "USD",
          predicted_ltv: (subscription.name === 'Annual' ? 12 : 1 ) * subscription.price * 2,
          value: (subscription.name === 'Annual' ? 12 : 1 ) * subscription.price,
        });
        const subscribeDate = moment.date().format('YYYY-MM-DD HH:MM:SS');
        klaviyo.track('subscribe', {subscribeDate, onBoarding: false, subscribed: true});
      }

      //Create car
      const carRef = Firebase.firestore().collection('cars').doc();
      car.readableTitle = `${car.year} ${car.make} ${car.model}'`;
      await carRef.set({...car, owner: user.id, timeCreated: new Date()});

      //Create order
      order.user = `${user.firstName} ${user.lastName}`;
      order.userID = user.id;
      order.carID = carRef.id;
      order.car = `${car.year} ${car.make} ${car.model}`;
      // order.selectedDate = order.selectedDate.format("YYYY-MM-DD HH:mm:ss")
      // order.start = order.start.format('H:mm');
      // order.end = order.end.format('H:mm');
      const orderRef = Firebase.firestore().collection('orders').doc();
      await orderRef.set({
        ...order,
        selectedDate: order.selectedDate.format("YYYY-MM-DD HH:mm:ss"),
        start: order.start.format('H:mm'),
        end: order.end.format('H:mm')
      });
      const geoResponse = await Geocode.fromAddress(order.shipping);
      const { lat, lng } = geoResponse.results[0].geometry.location;

      const coordinates = new Firebase.firestore.GeoPoint(lat, lng);
      await Firebase.geo.collection('locations').doc(orderRef.id).set({
        coordinates,
        id: orderRef.id,
        status: 'active',
        type: 'order'
      });


      //Track in CRMs
      ReactPixel.track('Purchase', {currency: "USD", value: order.items.reduce( (a,c) => a + c.price, 0 ) });
      const today = moment.date().format('YYYY-MM-DD HH:MM:SS');
      klaviyo.track('order', {orderDate: today});

      //Send SMS to user for confirm
      params = {
        function: 'sendSMS',
        contentType: 'application/x-www-form-urlencoded',
        variables: {
          message: `Hi ${user.firstName},${"\n"}We got your order, and you are all set for ${order.selectedDate.format("MMMM Do")} between ${order.start.format("h:mm a")} and ${order.end.format("h:mm a")}. We are excited to get you clean and are looking forward to ${order.selectedDate.format("dddd")}!${"\n"}If you need to make a change, no problem!  Let us know by downloading the app and editing your order. Download here: https://checkout.washr.app/download`,
          phoneNumber: "+1"+user.phoneNumber.replace(/[^0-9]/g, '')
        },
      };
      response = await RequestManger.post(params);

      //Turn off loading;
      this.toggleLoading();

      return Promise.resolve(true);
    }
    catch(e){
      //do things.
      console.log(e);
      this.setMessage(e.message || 'An uknown error occurred.  Please try again');
      this.openError(true);
      this.toggleLoading(true);
      return Promise.resolve(false);

    }
  }
}

const store = new Store();
export default store;
