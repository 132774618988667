import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Store from '../../services/store.js';
import MomentUtils from '@date-io/moment';
const moment = new MomentUtils();


const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review() {
  const classes = useStyles();
  const {order, user, payment, subscription} = Store;
  console.log(order, user, payment, subscription);

  const products = [
    ...order?.items?.map( item => ({ name: item.id, desc: item.desc, price:item.price > 0 ? item.price.toFixed(2) : 'Free'}) ),
    { name: 'Shipping', desc: '', price: 'Free' },
  ];
  const addresses = order.shipping;
  const start = typeof order.start === 'string'? order.start : order.start.format('h:mm a');
  const end = typeof order.end === 'string'? order.end : order.end.format('h:mm a');
  const time = moment.date(order.selectedDate).format('MMM Do') + ` between ${start} and ${end}`;
  const payments = [
    { name: 'Card type', detail: payment.card.brand.charAt(0).toUpperCase() + payment.card.brand.slice(1) },
    { name: 'Card holder', detail: `${user.firstName} ${user.lastName}` },
    { name: 'Card number', detail: `xxxx-xxxx-xxxx-${payment.card.last4 || 1234}` },
    { name: 'Expiry date', detail: payment.card.exp_month +'/'+ payment.card.exp_year || '04/2024' },
  ];

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        {products.map((product) => (
          <ListItem className={classes.listItem} key={product.name}>
            <ListItemText primary={product.name} secondary={product.desc} />
            <Typography variant="body2">{product.price}</Typography>
          </ListItem>
        ))}
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            ${order.items.reduce((a, b) => a+b.price, 0).toFixed(2)}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Subscription
          </Typography>
          <Typography gutterBottom>{(subscription.name || 'Monthly')+' Unlimited - $'+ (subscription.price || 49) +'/mo'}</Typography>
          <Typography gutterBottom>{subscription.name === 'Annual' ? '7 day free trial.  Billed annually' : 'Billed monthly'}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Wash location
          </Typography>
          <Typography gutterBottom>{addresses}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Appointment time
          </Typography>
          <Typography gutterBottom>{time}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Payment details
          </Typography>
          <Grid container>
            {payments.map((item) => (
              <React.Fragment key={item.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{item.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{item.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
