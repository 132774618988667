import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SwipeableViews from 'react-swipeable-views';
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import clay from "../../assets/images/clay.jpg";
import wash from "../../assets/images/wash.jpg";
import Store from '../../services/store.js';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import CreditCardInput from 'react-credit-card-input';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CircularProgress from '@material-ui/core/CircularProgress';
import {isMobile} from 'react-device-detect';

let setSub = false;

export default function PaymentForm() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const toggleOpen = () => setOpen(!open);
  const [message, setMessage] = React.useState('');
  const [selected, setSelected] = React.useState(Store.subscription.name);
  setSub = (name) => {
    setSelected(name);
    Store.subscription = name === 'Annual'
      ? {id:'unlimited-annual', name: 'Annual', price: 39, days: 7}
      : {id: 'unlimited-monthly', name: 'Monthly', price: 49, days: 0};
  };
  const [trial, setTrial] = React.useState(7);
  Store.setTrial = setTrial;

  const stripe = useStripe();
  const elements = useElements();

  const getPaymentMethod = async () => {
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (error) console.log(error.message); //NEED ERROR HANDLING HERE

    console.log(paymentMethod);
    Store.payment = paymentMethod;
    Store.toggleLoading();
  };
  Store.getPaymentMethod = getPaymentMethod;

  const validate = (e) => {
    Store.setDisabled(!e.complete);
    Store.paymentComplete = e.complete;
    setMessage(!!e.error ? e.error.message : '');
  }

  const update = (object, value) => {
    validate(value);
    Store.update(object, value);
  }

  const [showCardInput, setShowInput] = React.useState(!Store.payment.card);
  let payments = [];
  if(!showCardInput){
    const { user, payment } = Store;
    payments = [
      // { name: 'Card type', detail: 'Visa' },
      // { name: 'Card holder', detail: `Max Tanski` },
      // { name: 'Card number', detail: `xxxx-xxxx-xxxx-${1234}` },
      { name: 'Card type', detail: payment.card.brand.charAt(0).toUpperCase() + payment.card.brand.slice(1) },
      { name: 'Card holder', detail: `${user.firstName} ${user.lastName}` },
      { name: 'Card number', detail: `xxxx-xxxx-xxxx-${payment.card.last4 || 1234}` },
    ];
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment method
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {
            showCardInput &&
              <>
                { open && <CircularProgress />}
                <CardElement
                  onChange={validate}
                  onReady={(el) =>{
                    toggleOpen();
                    el.focus();
                  }}
                />
              </>
          }
          {
            !showCardInput &&
              <>
                {
                  payments.map( item =>
                    <Grid style={{maxWidth: '100%'}} key={item.name} item xs={6}>
                      <Typography gutterBottom>{item.name} - {item.detail}</Typography>
                    </Grid>
                  )
                }
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowInput(true)}
                  >
                    Change payment method
                  </Button>
                </Grid>
              </>
          }
          <Typography variant="caption" gutterBottom>
            {message}
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h6" style={{marginTop: 20,}}  gutterBottom>
        Promo code
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="promo"
            name="promoCode"
            label="Promo code"
            fullWidth
            onChange={(e) => Store.update('subscription', e)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={Store.applyPromo}
          >
            Apply promo
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h6" style={{marginTop: 20,}}  gutterBottom>
        Subscription choice
      </Typography>
      <Typography variant="caption" style={{marginBottom: 20}} gutterBottom>
        Choose the one that fits you best.
      </Typography>

      <Grid container spacing={3}>
      {
        isMobile ? (
          <SwipeableViews style={{marginTop: 20, padding: '0 30px',}} slideStyle={{padding: '0 10px'}}>
            {subscriptions.map(subscription =>
              <div style={{paddingBottom: 20}}>
                <Subcard sub={subscription} selected={selected} trial={trial}/>
              </div>
            )}
          </SwipeableViews>
        )
        : subscriptions.map(subscription =>
            <Grid item xs={12} md={6} style={{paddingBottom: 20}}>
              <Subcard sub={subscription} selected={selected} trial={trial}/>
            </Grid>
          )
      }
      </Grid>
    </React.Fragment>
  );
}

const Subcard = (props) => {
  let { sub, selected, trial } = props;
  const allFeatures = [
    `${trial} day free trial`,
    'Covers all your vehicles',
    'Unlimited washes',
    // 'Fully on-demand & mobile',
    // 'Always hand-washed',
    // 'Premium lubricants',
    // 'Spot-free rinses',
    // 'Deionized water',
  ];

  sub.features = sub.id === 'monthly' ? allFeatures.slice(1) : allFeatures;
  const classes = useStyles();
  return (
    <Card className={classes.card} onClick={() => setSub(sub.name)}>
        <CardMedia
          className={classes.media}
          image={sub.image}
        >
          { selected === sub.name && (
            <>
              <div
                style={{width: '100%', height: '100%', opacity: 0.5, position: 'absolute', left: 0, top: 0, backgroundColor: '#F5A623'}}
              />
              <CheckIcon
                style={{width: '100%', height: '100%', color: '#FFFFFF', position: 'absolute', left: 0, top: 0, marginBottom: 2}}
              />
            </>
          ) }
        </CardMedia>
        <CardContent className={classes.content}>
          <div style={{ display: "flex", justifyContent:'space-between' }}>
            <Typography
              className={"MuiTypography--heading"}
              variant={"h6"}
              gutterBottom
            >
              {sub.name}
            </Typography>
            <Typography
              className={"MuiTypography--heading"}
              variant={"subtitle1"}
              gutterBottom
            >
              ${sub.price}/mo
            </Typography>
          </div>
          <Typography
            className={"MuiTypography--subheading"}
            variant={"caption"}
          >
            {sub.desc}
          </Typography>
          {allFeatures.map((feature) => (
            <div>
              <Divider className={classes.divider} light />
                <Grid container xs={12} sm={6} justify="space-between">
                {sub.features.includes(feature) ? <CheckIcon style={{color: 'green'}}/> : <ClearIcon style={{color: 'red'}}/> }
                <Typography variant="body1" style={{"paddingRight": 7}}>{feature}</Typography>
              </Grid>
            </div>
          ))}
        </CardContent>
    </Card>
  );
}

const validateFunctions = {
  cardName: (value) => value.split(' ')?.length > 1 && value.split(' ')?.pop()?.length > 1,
  number: (value) => value.length >= 15 && value.length <= 19,
  expDate: (value) => value.split('/').length === 2
    && value.split('/')[0]?.length === 2 && +value.split('/')[0] > 0 && +value.split('/')[0] <= 12
    && value.split('/')[1]?.length === 4 && +value.split('/')[1] > 0 && +value.split('/')[1] <= new Date().getFullYear()+10
    && +value.split('/')[1] >= new Date().getFullYear() && new Date(value.split('/')[1], value.split('/')[0]-1, 31) - new Date() >= 0,
  cvv: (value) => value.length === 3 || value.length === 4,
}

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  card: {
    direction: "inline-block",
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    },
    overflow: 'none',
  },
  media: {
    paddingTop: "56.25%",
    position: "relative"
  },
  content: {
    textAlign: "left",
    padding: theme.spacing.unit * 3
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`
  },
  heading: {
    fontWeight: "bold"
  },
  subheading: {
    lineHeight: 1.8
  },
}));



const subscriptions = [
  {id:'annual', name:'Annual', price:39, desc:'Want to save big?  Get 20% off by being billed annually', image: clay,},
  {id:'monthly', name:'Monthly', price:49, desc:'Try us month to month.  Unlimited car washes for all your vehicles!', image: wash,}
];


//const [selected, setSelected] = React.useState('Annual');
// <Grid item xs={12} md={6}>
//   <TextField
//     required
//     id="cardName"
//     label="Name on card"
//     fullWidth
//     autoComplete="cc-name"
//     onChange={Store.updatePayment}
//     onBlur={validate}
//     error={errors['cardName']}
//   />
// </Grid>
// <Grid item xs={12} md={6}>
//   <TextField
//     required
//     id="number"
//     label="Card number"
//     fullWidth
//     autoComplete="cc-number"
//     onChange={Store.updatePayment}
//     onBlur={validate}
//     error={errors['number']}
//     helperText={errors['number'] && 'Card numbers must contain 16 or 19 digits long'}
//   />
// </Grid>
// <Grid item xs={12} md={6}>
//   <TextField
//     required
//     id="expDate"
//     label="Expiry date"
//     fullWidth autoComplete="cc-exp"
//     onChange={Store.updatePayment}
//     onBlur={validate}
//     error={errors['expDate']}
//     helperText={'MM/YYYY format'}
//   />
// </Grid>
// <Grid item xs={12} md={6}>
//   <TextField
//     required
//     id="cvv"
//     label="CVV"
//     helperText="Last three or four digits on signature strip"
//     fullWidth
//     gutterBottom
//     autoComplete="cc-csc"
//     onChange={Store.updatePayment}
//     onBlur={validate}
//     error={errors['cvv']}
//   />
// </Grid>

// <CreditCardInput
//   cardNumberInputProps={{onChange: (e) => update('payment', e) }}
//   cardExpiryInputProps={{onChange: (e) => update('payment', e) }}
//   cardCVCInputProps={{onChange: (e) => update('payment', e) }}
// />

// const validate = (e) => {
//   const validator = validateFunctions[e.target.id] || function() { return true };
//   const valid = validator(e.target.value);
//   setErrors({...errors, [e.target.id]: !valid});
//   const hasErrors = Object.values(errors).filter(x => x).length !== 0
//   const hasData = Store.paymentIsValid();
//   // console.log(Store.payment)
//   // console.log(hasErrors, hasData);
//   if(!hasErrors && hasData) Store.setDisabled(false);
// }
