import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';
import Store from '../../services/store.js';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import MomentUtils from '@date-io/moment';
const moment = new MomentUtils();

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function AppointmentFrom() {
  const [user, setUser] = React.useState(Store.user);
  const [car, setCar] = React.useState(Store.car);
  const [shipping, setShipping] = React.useState(Store.order.shipping);
  const [errors, setErrors] = React.useState({});
  const [date, setDate] = React.useState(Store.order.selectedDate);
  const [start, setStart] = React.useState(Store.order.start);
  const [end, setEnd] = React.useState(Store.order.end);
  const updateFunctions = {
    user: setUser,
    car: setCar,
    selectedDate: setDate,
    start: setStart,
    end: setEnd,
  };

  React.useEffect(() => {
    if( end.diff(start, 'hours') < 3 ){
      const value = moment.date(start).add(3, 'hours');
      Store.update('order', {target: {id: 'end', value }});
      setEnd( value );
    }
  }, [start]); // Only re-run the effect if count changes

  const validate = (e) => {
    const validator = validateFunctions[e.target.id] || function() { return true };
    const valid = validator(e.target.value);
    setErrors({...errors, [e.target.id]: !valid});
    const hasErrors = Object.values(errors).filter(x => x).length !== 0
    const hasData = Store.userIsValid() && Store.carIsValid() && Store.orderIsValid();
    if(!hasErrors && hasData) Store.setDisabled(false);

    if(Store.userIsValid() && !Store.user.id && !Store.creatingUser) Store.creatingUser = Store.createUser();
  }

  const update = (object, e) => {
    validate(e);
    Store.update(object, e);
  }

  const updateTime = (label, value) => {
    // console.log(value);
    validate({ target: {id: label, value: (label === 'start' ? value : {start, end: value}) } });

    Store.update('order', {target: {id: label, value }});
    updateFunctions[label](value);
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Personal Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={user.firstName || ''}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            onChange={(e) => update('user', e)}
            onBlur={validate}
            error={errors['firstName']}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={user.lastName || ''}
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            onChange={(e) => update('user', e)}
            onBlur={validate}
            error={errors['lastName']}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={user.phoneNumber || ''}
            id="phoneNumber"
            name="phoneNumber"
            label="Phone number"
            fullWidth
            autoComplete="tel-country-code"
            onChange={(e) => update('user', e)}
            onBlur={validate}
            error={errors['phoneNumber']}
            helperText={errors['phoneNumber'] && 'Phone numbers must contain 10 digits'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={user.email || ''}
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            onChange={(e) => update('user', e)}
            onBlur={validate}
            error={errors['email']}
            helperText={errors['email'] && 'That email looks funny.  Is it spelled correctly?'}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" style={{paddingTop: 20}} gutterBottom>
        Car Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={car.year || ''}
            id="year"
            name="year"
            label="Year"
            fullWidth
            onChange={(e) => update('car', e)}
            onBlur={validate}
            error={errors['year']}
            helperText={errors['year'] && 'Year must be a numeric value and 4 digits'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={car.make || ''}
            id="make"
            name="make"
            label="Make"
            fullWidth
            onChange={(e) => update('car', e)}
            onBlur={validate}
            error={errors['make']}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={car.model || ''}
            id="model"
            name="model"
            label="Model"
            fullWidth
            onChange={(e) => update('car', e)}
            onBlur={validate}
            error={errors['model']}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={car.color || ''}
            id="color"
            name="color"
            label="Color"
            fullWidth
            onChange={(e) => update('car', e)}
            onBlur={validate}
            error={errors['color']}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" style={{paddingTop: 20}} gutterBottom>
        Appointment Information
      </Typography>
      <Typography variant="subtitle2" style={{paddingTop: 20}} gutterBottom>
        Wash location
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <GooglePlacesAutocomplete
            apiKey="AIzaSyCbN57ey07wtiRq2QJmkZrWELKTBlYzn20"
            selectProps={{
              defaultInputValue: shipping,
              placeholder: 'Address...',
              onChange: (e) => update( 'order', {target: {id: 'shipping', value: e.label}} ),
              onBlur: validate
            }}
          />
        </Grid>
      </Grid>
      <Typography variant="subtitle2" style={{paddingTop: 40}} >
        Date and time
      </Typography>
      <Typography variant="caption" gutterBottom>
        Please select a minimum of a 3 hour time window for your service.
      </Typography>
      <Grid container spacing={3} style={{paddingTop: 10}}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            id={"selectedDate"}
            label="Appointment date"
            value={date}
            onChange={(e) => updateTime('selectedDate', e.format("YYYY-MM-DD HH:mm:ss"))}
            onBlur={validate}
            shouldDisableDate={getOffDays}
            fullWidth
            animateYearScrolling
            disablePast
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TimePicker
            id="start"
            label="Time slot start"
            value={start}
            minutesStep={5}
            onChange={(e) => updateTime("start", e)}
            error={errors['start']}
            helperText={errors['start'] && "Appointment start times are between 8am and 6pm"}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TimePicker
            id="end"
            label="Time slot end"
            value={end}
            minutesStep={5}
            onChange={(e) => updateTime("end", e)}
            error={errors['end']}
            helperText={errors['end'] && "Time windows must be atleast 3 hours long"}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

//            value={selectedDate}

function getOffDays(date) {
  return date.isoWeekday() === 3 || date.isoWeekday() === 7 || date.isSame(new Date(), 'day');
}




const validateFunctions = {
  firstName: (value) => value.length > 0,
  lastName: (value) => value.length > 0,
  phoneNumber: (value) => value.replace(/[^0-9]/g, '').length === 10,
  email: (value) => value.split("@")[1]?.indexOf(".") > -1,
  year: (value) => value.length === 4 && +value > 0,
  make: (value) => value.length > 2,
  model: (value) => value.length > 2,
  color: (value) => value.length > 2,
  start: (value) => value.hour() <= 18 && value.hour() >= 8,
  end: (value) => value.end.diff(value.start, 'hours') >= 3,
}


//Old address form
// <Grid item xs={12}>
//   <TextField
//     required
//     id="address1"
//     name="address1"
//     label="Address"
//     fullWidth
//     autoComplete="shipping address-line1"
//     onChange={Store.updateOrder}
//     onBlur={validate}
//   />
// </Grid>
// <Grid item xs={12}>
//   <TextField
//     id="address2"
//     name="address2"
//     label="Address line 2"
//     fullWidth
//     autoComplete="shipping address-line2"
//     onChange={Store.updateOrder}
//   />
// </Grid>
// <Grid item xs={12} sm={6}>
//   <TextField
//     required
//     id="city"
//     name="city"
//     label="City"
//     fullWidth
//     autoComplete="shipping address-level2"
//     onChange={Store.updateOrder}
//     onBlur={validate}
//   />
// </Grid>
// <Grid item xs={12} sm={6}>
//   <TextField
//     required
//     id="state"
//     name="state"
//     label="State/Province/Region"
//     fullWidth
//     onChange={Store.updateOrder}
//     onBlur={validate}
//   />
// </Grid>
// <Grid item xs={12} sm={6}>
//   <TextField
//     required
//     id="zip"
//     name="zip"
//     label="Zip / Postal code"
//     fullWidth
//     autoComplete="shipping postal-code"
//     onChange={Store.updateOrder}
//     onBlur={validate}
//   />
// </Grid>
